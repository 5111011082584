import React, {useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import './SideBar.scss'
import { AppContext } from '../../global/context' 
import { House, Sticky, StickiesFill, ShieldLock, ListColumns, Gear, DoorOpen, Receipt } from "react-bootstrap-icons";


function SideBar() { 
    
  //-- recebo os parametros do context    
  const {isSidebarOpen, closeSidebar, getActiveUser, token} = useContext(AppContext); 
  const [procard, setIlpi] = useState();
  const { innerWidth: width} = window;
  const name_class = isSidebarOpen ? 'bg-light border-right sidebar-wrapper' : 'bg-light border-right sidebar-wrapper open';
  const [config] = React.useState({});  
    
  const handleLogout = () => {
    sessionStorage.clear();
    //window.location.pathname = '/#logout';        
    //window.location.href = '/public/';        
    window.location.href = '/';        
    //sessionStorage.removeItem("token");        
  }

  React.useEffect(() => {
    
    //-- Fecha a sidebar no primeiro carregamento
    //if(width < 700) closeSidebar();
    const loadData = async () => {        
        // const retorno = await fetchData.getConfig2(token);                
        
        // //-- Sessão expirada
        // if(retorno.code === 401){            
        //     sessionStorage.clear();    
        //     window.location.href = '/';      
        // }        
        // setConfig(retorno) 
        
        const active = getActiveUser()
        setIlpi(active)      
    };   
    loadData();   
  }, [width, token, closeSidebar, getActiveUser]);

  const handleClick = () => {
      //isSidebarOpen ? closeSidebar() : openSidebar();     
      if(width < 700) closeSidebar()    
  }
  
  return(
    <div className={name_class} >
        <div className="top-sidebar-heading">
            <span className="navbar-brand mb-2" href="#">
                <img src='assets/images/logo-white.png' width="26" height="26" alt=""/>                
            </span>  
            <span className="sidebar-head-title">Finance Pro</span>
        </div>

        <div className="list-group list-group-flush" id="menu-lateral">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: PRINCIPAL .:</span>
        </h6>
        
        <ul className="nav flex-column mb-2">    
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/'><House style={{fontSize: "1.2rem", marginRight:"5px"}}></House> Dashboard</NavLink>
            </li>            
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/faturas'><StickiesFill style={{fontSize: "1.2rem", marginRight:"5px"}}></StickiesFill> Faturas Fechadas</NavLink>
            </li>            
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/fatura_parcial'><Sticky style={{fontSize: "1.2rem", marginRight:"5px"}}></Sticky> Faturas Parciais</NavLink>
            </li>  
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/boletos'><Receipt style={{fontSize: "1.2rem", marginRight:"5px"}}></Receipt> Boletos</NavLink>
            </li> 
            {/* <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/boletos'><i className="bi-receipt-cutoff" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Gerar Boleto de Pgto.</NavLink>
            </li>               */}
            {/* <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/vendas'><i className="bi-bag" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Compras</NavLink>
            </li> */}
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/receitas_pendentes'><ShieldLock style={{fontSize: "1.2rem", marginRight:"5px"}}></ShieldLock> Receitas Pendentes</NavLink>
            </li>            
        </ul>

        {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: USUÁRIOS .:</span>
        </h6>
        <ul className="nav flex-column mb-2">
            <li className="nav-item">            
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/usuarios'><i className="bi-person" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Usuários</NavLink>
            </li>
        </ul> */}

        {/* título do cardápio */}
        {(config.show_cardapio === "1" || config.link_cardapio !== "") && 
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>.: PRODUTOS/PREÇOS .:</span>
            </h6>
            <ul className="nav flex-column mb-2">
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/lista_precos'><ListColumns style={{fontSize: "1.2rem", marginRight:"5px"}}></ListColumns> Consulta de preços</NavLink>
                </li>              
            </ul>            
        </>
        }


        <ul className="nav flex-column mb-2">                
            
            {procard && procard.tipo === "SUR" &&
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/configuracao'><Gear className="bi-gear" style={{fontSize: "1.2rem", marginRight:"5px"}}></Gear> Configurações</NavLink>
                </li>  
            }


            <li className="nav-item">
                <NavLink 
                    className='nav-link' 
                    onClick={() => handleLogout()}
                    to='/logout'>
                    <DoorOpen style={{fontSize: "1.2rem", marginRight:"5px", color: "red"}}></DoorOpen>                     
                    Sair
                </NavLink>
            </li>            
        </ul>              
        </div>    
    </div>
  )
}

export default SideBar;



