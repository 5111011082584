import React, {useState, useContext, useMemo, useCallback} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import TableHeader from '../../components/TableHeader/TableHeader';
import moment from 'moment';
import { PersonLinesFill , Receipt, UpcScan, PencilSquare, EnvelopeAt, HouseCheck } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Faturas.scss";
import EditFatura from './EditFatura';
import DetalheFatura from './DetalheFatura';
// import Swal from 'sweetalert2'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <><input 
        type="text" 
        value={filterText} 
        onChange={onFilter}
        placeholder="Filtrar"
        aria-label="Search Input"
        className="search-input"           
        id="input-text-filter"
      />
      <button 
        type="button" 
        className="search-input-button"
        onClick={onClear}>X            
      </button></>
);

function Faturas({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const {token, refresh, getActiveUser} = useContext(AppContext); 
    const [filterText, setFilterText] = useState('');
	  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const MySwal = withReactContent(Swal); 
    const [showModal, setShowModal] = useState(false);
    const [showDetalheModal, setShowDetalheModal] = useState(false);
    const [empresas, setEmpresas] = useState([])
    const [detalhes, setDetalhes] = useState([])
    
    let startdate = moment();
    startdate = startdate.subtract(1, "M");
    startdate = startdate.format("YYYY-MM-DD");

    const [fatura, setFatura] = useState({});
    
    //-- Seta o título 
    React.useEffect(() => { 
      setTitle('Faturas');      
    },[setTitle])     
    
    
    const loadFaturasEmpresas = useCallback(async () => {
      setLoading(true);        
      const user = getActiveUser();      
      
      //-- Feito logout
      if(!user || user === null){        
        // Swal.fire('Sessão Expirada', 'Faça o login novamente', 'warning');
        window.location.href = '/'; 
        return;     
      }      
      
      const dados = {ilpis: user.ilpis, user_id: user.id}  
      const retorno = await fetchData.getFaturas(dados, token);  
      
      //-- Sessão expirada
      if(retorno.code === 401){        
        setLoading(false);
        sessionStorage.clear();        
        window.location.href = '/';  
      }
      setTableData(retorno.items)
      
      const resp = await fetchData.getIlpis({usuario_id: user.id}, token);  
      setEmpresas(resp.items);   

      setLoading(false);
    },[setLoading, getActiveUser, token])


   
    //-- Carrega as ultimas faturas
    React.useEffect(() => {   
      loadFaturasEmpresas();   
    }, [loadFaturasEmpresas, refresh]);    
    
    
    const filteredItems = tableData.filter(
      item => item && (item.nome.toLowerCase().includes(filterText.toLowerCase()) || 
                      (item.status.toLowerCase().includes(filterText.toLowerCase()))
      )      
    );


    const handleEditButtonClick = async (fatura_id) => {      
      if(!fatura_id || fatura_id <= 0) return;
      setLoading(true);                
      const retorno = await fetchData.getFatura(fatura_id, token);        
      setFatura(retorno.items)
      // setFatura({...fatura, cancelado: (fatura.status === "CANCELADO" || fatura.status === "CANCELED")})
      // console.log(retorno.items);
      setShowModal(true)
      setLoading(false);
    };


    const handleDetalheModalButtonClick = async (fatura_id, tipo_envio_fatura) => {      
      if(!fatura_id || fatura_id <= 0) return;
      setLoading(true);  
      
      setFatura({
        id: fatura_id,
        ilpi_id: 0,
        cod_cliente: 0,
        inicio: null,
        fim: null,
        vencimento: null,
        valor_sdm: 0,
        status: 'NEW',
        tipo_envio_fatura
      });      
      const dados = {fatura_id}  
      const retorno = await fetchData.getDetalhesFatura(dados, token);  
      
      //-- Sessão expirada
      if(retorno.code === 200){
        setDetalhes(retorno.items)
        setShowDetalheModal(true)
      }
      setLoading(false);
    };


    // const boletoCasaClick = async (fatura_id, nosso_numero) => {
    //   if(fatura_id <= 0){
    //     MySwal.fire({title: "Oops!", html: 'Fatura não localizada!', icon: "error"});
    //   }

    //   // setLoading(true);   
    //   const retorno = await fetchData.getBoletoPDF(fatura_id, nosso_numero, token);  

    //   if (retorno.status === "success") {            
    //     loadFaturas();
    //     MySwal.fire({title: "Tudo certo!", html: retorno.message, icon: "success"}
    //     )                          
    //   }
    //   else{        
    //     MySwal.fire({title: "Oops!", html: retorno.message, icon: "error"});
    //   }
    //   setLoading(false);   
    // }

    
    const handleNovoClick = async () => {      
      setFatura({
        id: 0,
        ilpi_id: 0,
        cod_cliente: 0,
        inicio: startdate,
        fim: moment().format('YYYY-MM-DD'),
        vencimento: moment().format('YYYY-MM-DD'),
        valor_sdm: 0,
        status: 'NEW',
        tipo_envio_fatura: ''
      });
      setShowModal(true)
    };

    const onSubmitForm = (event) => {
      
      //event.preventDefault();
      // const fatura_id = event.target.fatura_id.value;
      // setFatura({...fatura, cod_cliente: 0});      
      // console.log(fatura_id)

    }


    const submitFatura = (event, id, cod_vsm, tipo, cod_cliente) => {      
      
      if(cod_vsm === '0'){
        event.preventDefault();
        MySwal.fire({title: "Oops!", html: 'Cliente sem código VSM no cadastro de ILPI!', icon: "warning"});
      }
      
      if(tipo === 'casa')
        setFatura({...fatura, id: id, cod_cliente: 0});      

      if(tipo === 'familia')
        setFatura({...fatura, id: id, cod_cliente})      
  
    }


    const enviarEmailFaturaBoleto = async (id, cod_cliente, tipo, emailcli, showEditMail) => {      
      
      let _tipo =  (tipo === 'C' || tipo === 'E') ? 'empresa' : 'familia';
      let email = (_tipo === 'empresa') ? emailcli : '';

      //-- exibe form para obter o email, caso o usuário queira enviar para outro email
      if(showEditMail && (_tipo === 'empresa')){
        const { value: sendmail } = await Swal.fire({
          title: 'Entre com o email:',
          input: 'email',
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          cancelButtonText: 'Cancelar',
          inputValue: email
          // inputLabel: 'EMail',
          // inputPlaceholder: ''
        })
        if (!sendmail) return;
        email = sendmail;
      }
      else if(!showEditMail){
        if(!email || email === ""){
          MySwal.fire({title: "EMail Vazio", html: 'EMail do cliente não cadastrado no VSM.', icon: "error"});
          return;
        }
      }
      
      setLoading(true);        
      const dados = {fatura_id: id, cod_cliente, tipo: _tipo, email}  
      const retorno = await fetchData.enviarEmailFaturaBoleto(dados, token);  
      
      //-- Sessão expirada
      setLoading(false);
      if(retorno.code !== 200 || retorno.items.status === 'error'){        
        MySwal.fire({title: "Oops!", html: retorno.items.mensagem, icon: "error"});
      }
      else{
        MySwal.fire({title: "Tudo certo!", html: retorno.items.mensagem, icon: "success"})
      }
            
    }


    const abrirOuGerarBoletosCasa = async (nosso_numero, fatura_id, cnpj, tipo_envio) => {
     
      if(nosso_numero && nosso_numero !== ""){
        openLinkNewTab(nosso_numero, 0, 0, '');
        return;
      }

      if(!cnpj || cnpj === ""){
        MySwal.fire({title: "Oops!", html: 'Empresa sem cnpj cadastrado!', icon: "warning"});
        return;
      }
      
      if(tipo_envio === 'C' || tipo_envio === 'E'){
        const link = fetchData.getURL('/api/getBoleto/casa/' +fatura_id+ '/0');
        window.open(link, "_blank");
        return;
      }

      await MySwal.fire({
        title: "Confirma geração de boletos?",
        text: 'Esta ação vai gerar um boleto para cada residente da ILPI. Confirma?', 
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, gerar boletos!',
        cancelButtonText: 'Não!',        
        dangerMode: true,
      })
      .then(async(result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const tipo = tipo_envio === 'C' ? 'casa' : 'familia';
          const dados = {fatura_id, tipo}  
          const retorno = await fetchData.gerarBoletosFatura(dados, token);            
          setLoading(false);
          
          //-- Sessão expirada
          if(retorno.items.code === 200){                
            MySwal.fire({title: "OK!", html: retorno.items.mensagem, icon: "success"});  
          }  
          else{
            MySwal.fire({title: "Ops!", html: retorno.items.mensagem, icon: "warning"});  
          }       
        }
      })
    }



    const openLinkNewTab = (nosso_numero, fatura_id, codcli, cpfcnpj) => {
      
      if(nosso_numero && nosso_numero !== ""){
        const link = fetchData.getURL('/api/getBoletoPDF/' + nosso_numero);
        window.open(link, "_blank");
        return;
      }
      
      if(!cpfcnpj && cpfcnpj === ""){
          MySwal.fire({title: "Oops!", html: 'Cliente sem cpf/cnpj cadastrado no VSM!', icon: "warning"});
          return;
      }            
      
      const link = fetchData.getURL('/api/getBoleto/familia/' +fatura_id+ '/' +codcli);
      window.open(link, "_blank");
    }


    const handleDeleteFaturaResidente = (fatura_id, codcli) => {
      MySwal.fire({
        title: "Confirma a exclusão da fatura?",
        text: "Uma vez exluído, não será possível reativar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Excluir!',
        cancelButtonText: 'Não!',        
        dangerMode: true,
      })
      .then(async(result) => {  
        if(result.value){
          let dados = {fatura_id, codcli}  
          let retorno = await fetchData.cancelFatura(dados, token);
          if (retorno.status === "error") {
              MySwal.fire({
                  title: "Oops!",
                  html: retorno.mensagem,
                  icon: "error",
              });
              return;
          } 
          //-- Recarrega os dados da lista
          setLoading(true);  
          dados = {fatura_id}  
          retorno = await fetchData.getDetalhesFatura(dados, token);  
          if(retorno.code === 200)
            setDetalhes(retorno.items)
          setLoading(false);
        }
      })  
    }


    const onSubmitSaveFatura = async (evt) => {
      evt.preventDefault();
      
      if(!fatura.ilpi_id === '-1' || !fatura.ilpi_id){
        MySwal.fire({title: "Oops!", html: 'Selecione a ILPI.', icon: "warning"}); 
        setLoading(false);  
        return;
      }

      if(fatura.ilpi_id === '0'){
        MySwal.fire({title: "Oops!", html: 'Esta ILPI não tem um Código VSM associado (cadastro > ilpi).', icon: "warning"}); 
        setLoading(false);  
        return;
      }
      
      const retorno = await fetchData.salvarFatura(fatura, token);
      setShowModal(false);
      if (retorno.status === "success") {            
        loadFaturasEmpresas();
        MySwal.fire({title: "Tudo certo!", html: retorno.message, icon: "success"}
        )                          
      }
      else{        
        MySwal.fire({title: "Oops!", html: retorno.message, icon: "warning"});
      }
      setLoading(false);   
      //console.log(JSON.stringify(fatura))
      //Form submission happens here
    }

    //-- change dos campos do formulário de edição EditFatura
    const onFormChange = (evt) => {
        
      let value = evt.target.value;
      if(evt.target.type === 'checkbox'){
        value = (evt.target.checked) ? 'CANCELED' : 'NEW';
      }
      setFatura({...fatura, [evt.target.name]: value});    
    }
    
   
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {  
        if (filterText) {  
          setResetPaginationToggle(!resetPaginationToggle);  
          setFilterText('');  
        }  
      };
      return (  
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);


    // p.NOMECOMPLETO, cli.NOMECLI, v.DATA, v.QUANTPROD,  conv.CODEMP as CODEMP, emp.nomeemp
    const conditionalRowStyles = [
      {
        when: row => row.status === 'CANCELADO',
        style: {
          textDecoration: 'line-through',
          color: '#d263a2'        
        },
      },
    ];
    
    const colunas = [
      {
        name: 'ID',
        selector: row => row.id,        
        sortable: true,
        width: '7%'        
      },        
      {
        name: 'Cod. VSM',
        selector: row => row.cod_vsm,        
        sortable: true,
        width: '7%'        
      },        
      {
        name: 'Data Fechamento',
        //selector: row => row.data,   
        selector: row => moment(row.data).format('DD/MM/YYYY'),
        sortable: true,
        width: '14%'
        // {  name: 'DATA/HORA', selector: row => moment(row.data_hora_venda).format('DD/MM/YYYY HH:mm'), sortable: true, minWidth: '130px'},
      },    
      {
        name: 'Cliente/Casa',
        selector: row => row.nomecli !== '' ? (row.nomecli +' ('+ row.nome +')') : row.nome,   
        sortable: true,        
        width: '25%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
      },    
      {
        name: 'T.Envio',
        selector: row => row.tipo_envio_fatura,   
        sortable: true,        
        width: '8%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
      },  
      {
        name: 'Valor com Descontos',
        selector: row => ('R$ ' + (parseFloat(row.valor)).toFixed(2)),   
        sortable: true,
        width: '13%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
      },    
      {
        name: 'Status',
        selector: row => row.status,   
        sortable: true,
        width: '8%'
      },    
      {
        name: 'Fat./Bol.',        
        width: '8%',        
        cell:(row) => (row.status !== "CANCELADO" ?           
          <>                    
          <button
            className="icon-table me-1"             
            title="Fatura Casa"
            type="submit" 
            form="printFaturaCasa"            
            onClick={(e) => submitFatura(e, row.id, row.cod_vsm, 'casa', row.cod_cliente)}
            >            
            <HouseCheck color="royalblue" size={15} ></HouseCheck>
          </button>   
          <button
            className="icon-table me-1"             
            title="Fatura Família"
            type="submit" 
            form="printFaturaFamilia"
            onClick={(e) => submitFatura(e, row.id, row.cod_vsm, 'familia', row.cod_cliente)}            
            >            
            <PersonLinesFill color="royalblue" size={15} ></PersonLinesFill>
          </button>

          <button
            className="icon-table me-1"             
            title="Gerar/Exibir Boleto(s)"
            type="button"             
            onClick={() => abrirOuGerarBoletosCasa(row.nosso_numero, row.id, row.cnpj, row.tipo_envio_fatura)}            
            >            
            <UpcScan color={(row.nosso_numero && row.nosso_numero !== "") ? 'green' : 'gray'} size={15} ></UpcScan>
          </button>  
          </> : ''
        )
      },      
      {
        name: 'Ações',        
        width: '15%',
        cell:(row) => (row.status !== "CANCELADO" ?           
          <>
          <button
            className="icon-table me-1"             
            title="Editar Fatura"
            type="button"             
            onClick={() => handleEditButtonClick(row.id)}            
            >            
            <PencilSquare color="#39bfbf" size={15} ></PencilSquare>
          </button>
          <button
            className="icon-table me-1"             
            title="Detalhes da Fatura"
            type="button"             
            onClick={() => handleDetalheModalButtonClick(row.id, row.tipo_envio_fatura)}            
            >            
            <Receipt color="#e17eb0" size={15} ></Receipt>
          </button>          
          <button
            className="icon-table me-1"             
            title="Reenviar Email (Faturas e Boleto)"
            type="submit"             
            onClick={() => enviarEmailFaturaBoleto(row.id, 0, row.tipo_envio_fatura, row.email_notif1, true)}            
            >            
            <EnvelopeAt color="royalblue" size={15} ></EnvelopeAt>
          </button>
          </> : ''
        ),
        // ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,                        
      },    
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
    };


    return(     
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                        
          <EditFatura show={showModal} fatura={fatura} empresaLst={empresas} ilpi_id={fatura.ilpi_id} setShowModal={setShowModal} handleChange={onFormChange} onSubmitHandler={onSubmitSaveFatura} setFatura={setFatura}/>                            
          <DetalheFatura show={showDetalheModal} fatura={fatura} setFatura={setFatura} detalhes={detalhes} enviarEmailFaturaBoleto={enviarEmailFaturaBoleto} setShowDetalheModal={setShowDetalheModal} setLoading={setLoading} openLinkNewTab={openLinkNewTab} handleDeleteFaturaResidente={handleDeleteFaturaResidente} />                            
          <DataTable
            title={TableHeader('Últimas Faturas', true, handleNovoClick, false, '')}
            //title="Últimas Faturas"
            columns={colunas}
            striped={true}
            conditionalRowStyles={conditionalRowStyles}
            // defaultSortFieldId={0}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}            
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            //selectableRows
            persistTableHead                        
          />
        </div>  
        {fetchData.printForm('printFaturaCasa', 'api/printFatura', onSubmitForm, fatura, 'casa')}
        {fetchData.printForm('printFaturaFamilia', 'api/printFatura', onSubmitForm, fatura, 'familia')}        
      </div>
  )
}

export default Faturas;