import React from 'react';
// import './TableHeader.scss'
import Form from 'react-bootstrap/Form';
import './HeaderFilters.scss'

function HeaderFilters(title, onChange) { 
    
  return(
    <div className="table-header-component pe-2">
        <div className="table-title">{title}</div>

        <div className='form-selects'>          
          <Form.Select name="status" className="me-1" title="Status" onChange={(e) => onChange(e)}>            
            <option value="0">TODOS</option>
            <option value="CANCELADO">Cancelado</option>
            <option value="PAGO">Pago</option>
            <option value="EMABERTO">Em Aberto</option>
            <option value="VENCIDO">Vencido</option>
          </Form.Select>   

          <Form.Select name="qtde" title="Quantidade de Registros" onChange={(e) => onChange(e)}>             
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="5000">5000</option>
            <option value="10000">10000</option>
            <option value="0">TODOS</option>
          </Form.Select>   
        </div>
    </div>
  )
}

export default HeaderFilters;



