import React, {useState} from 'react';
import {  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { SquareFill, ArrowUp, ArrowDown } from "react-bootstrap-icons";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  // indexAxis: 'y',  
  plugins: {
    legend: { position: 'top'},
    title: {
      display: true,
      text: 'Vendas de Medicamentos',
    },
  },
};

function BarChart({chartData, setLoading}) { 

    const [data, setData] = useState()

    React.useEffect(() => {      
      setLoading(true);
      const labels = chartData.labels;      
      const aux = {
        labels,
        datasets: [
          {
            label: 'Vendas',      
            data: chartData.vendas,            
            backgroundColor: 'rgba(32, 201, 151, 0.5)',
          },        
          {
            label: 'SDM',      
            data: chartData.sdm,            
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          }, 
        ],
      };
      setData(aux);      
      setLoading(false);
    }, []);


    return(           
        <div className="card">
        <div className="card-header border-0">
            <div className="d-flex justify-content-between">
            <h3 className="card-title mt-3">Venda de Medicamentos/SDM</h3>                        
            </div>
        </div>
        <div className="card-body">
            <div className="d-flex">
            <p className="d-flex flex-column">
                {/* <span className="text-bold text-lg">820</span>
                <span>Visitors Over Time</span> */}
            </p>
            {chartData && 
            <p className="ml-auto d-flex flex-column text-right">
                {Math.round(chartData.porc_cresc) >= 0 &&
                  <span className="text-success">
                  <ArrowUp></ArrowUp> {Math.round(chartData.porc_cresc)}%
                  </span>
                }
                {Math.round(chartData.porc_cresc) < 0 &&
                  <span className="text-danger">
                  <ArrowDown></ArrowDown> {Math.round(chartData.porc_cresc)}%
                  </span>
                }
                <span className="text-muted">Faturamento - Mês atual em relação<br/> ao mês passado</span>
            </p>
            }
            </div>
            
            <div className="position-relative mb-4">            
              {data && 
                <Bar options={options} data={data} />
              }
            </div>

            {/* <div className="d-flex flex-row justify-content-end">
              <span className="mr-2">
                  <SquareFill className="text-primary"></SquareFill> This Week
              </span>

              <span>
                <SquareFill className="text-gray"></SquareFill> Last Week
              </span>
            </div> */}
        </div>
        </div>
  )
}

export default BarChart;