import React from 'react';
import axios from 'axios';

//const API_BASE = 'http://localhost/pagamentos/server/public';
// const API_BASE = 'https://natusnutri-maxi.procardbr.com.br/public';

const API_BASE = ((window.location.href).indexOf('localhost:3000') > 0) ? 'http://localhost/droganet/server/public' : 'https://droganetpro.com.br';

// console.log(API_BASE);
axios.defaults.timeout = 50000;

const basicFetch = async (endpoint, data, token, method = "POST") => {

    //const token = getToken();
    // axios.defaults.headers.common = {}
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000/';     

    try{
        const res = await axios({
        method: method,
        url: `${API_BASE}${endpoint}`, 
        data: data,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8", 
            //   "Access-Control-Allow-Origin": "*" 
        }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            message: res.data.message ? res.data.message : "success",
            code: (res.status) ? res.status : 200           
        }
        //console.log('retorno: ' + JSON.stringify(retorno));
        return retorno;        
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",               
            //message: error.response.data.message ? error.response.data.message : error.message,  
            //res.status(500).json({ msg: "something bad has occurred." });          
            message: (error.response !== undefined && error.response) ? error.response.data.message : error.message,            
            code:  (error.response !== undefined && error.response) ? error.response.status : 404
        }          
        //console.log('erro: ' + JSON.stringify(erro));
        return (erro);        
    }    
};

const basicGet = async (endpoint, data, token) => {

    try {
        const res = await axios({
            method: 'GET',
            url: `${API_BASE}${endpoint}`, 
            params: data,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",         
            }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            messsage: "success",
            code: 200           
        }
        return retorno;    
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",   
            message: (error.response !== undefined && error.response) ? error.response.data.message : error.message,            
            code:  (error.response !== undefined && error.response) ? error.response.status : 404
        }          
        return (erro);        
    }    
};

const basicDelete = async (endpoint, nosso_numero, token) => {

    try {
        const res = await axios({
            method: 'DELETE',
            url: `${API_BASE}${endpoint}/${nosso_numero}`,             
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",         
            }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            messsage: "success",
            code: 200           
        }
        return retorno;    
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.response.status           
        }          
        return (erro);        
    }    
};


const getURL = (endpoint) => {
    return `${API_BASE}${endpoint}`
}

const makeLogin  = async (data) => {    
    const resp = await basicFetch('/auth/login', data);        
    return resp.items;
}

const getProdutos = async (data, token) => {    
    const retorno = await basicFetch(`/api/produtos/search`, data, token)                 
    return {
        slug: 'produtos',
        title: 'produtos',
        code: retorno.code,
        items: retorno.items 
    };
}

const getControlados = async (data, token) => {    
    const retorno = await basicFetch(`/api/listar_pendentes`, data, token)                 
    return {
        slug: 'pendentes',
        title: 'pendentes',
        code: retorno.code,
        items: retorno.items 
    };
}

const getFaturas = async (data, token) => {    
    const retorno = await basicFetch(`/api/faturas/listar`, data, token)   
    return {
        slug: 'faturas',
        title: 'faturas',
        code: retorno.code,
        items: retorno.items 
    };
}

const printFatura = async (id, token) => {    
    const retorno = await basicGet(`/api/printFatura`, id, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        code: retorno.code,
        items: retorno.items 
    };
}

const getIlpis = async (data, token) => {    
    const retorno = await basicFetch(`/api/getIlpisFinanceiro`, data, token)   
    return {
        slug: 'empresas',
        title: 'empresas',
        code: retorno.code,
        items: retorno.items 
    };
}

const getResidentesIlpi = async (data, token) => {    
    const retorno = await basicFetch(`/api/faturas/getVSMClientesIlpi`, data, token)   
    return {
        slug: 'residentes',
        title: 'residentes',
        code: retorno.code,
        items: retorno.items 
    };
}

const salvarFatura = async (data, token) => {
    const retorno = await basicFetch(`/api/faturas/salvar`, data, token)
    return {
        slug: 'faturas',
        title: 'salvarFatura',
        code: retorno.code,
        items: retorno.items,
        status: retorno.items.status,
        message: retorno.items.message 
    };
}


const gerarBoleto = async (data, token) => {
    const retorno = await basicFetch(`/api/boletos/getBoleto`, data, token)
    return {
        slug: 'boletos',
        title: 'boletos',
        code: retorno.code,
        items: retorno.items,
        status: retorno.items.status,
        message: retorno.items.message 
    };
}

const getBoletos = async (data, token) => {    
    const retorno = await basicFetch(`/api/listarBoletos`, data, token)   
    return {
        slug: 'boleto',
        title: 'boletos',
        code: retorno.code,
        items: retorno.items 
    };
}

const getBoletoPDF = async (fatura_id, nosso_numero, token) => {    
    const retorno = await basicGet(`/api/getBoletoPDF/${fatura_id}/${nosso_numero}`, [], token)   
    return {
        slug: 'boletoPDF',
        title: 'boletoPDF',
        code: retorno.code,
        // items: retorno.items 
    };
}


const enviarEmailFaturaBoleto = async (data, token) => {    
    const retorno = await basicFetch(`/api/boletos/enviarEmailFaturaBoleto`, data, token)   
    return {
        slug: 'Email',
        title: 'Email',
        status: retorno.status,
        code: retorno.code,
        items: retorno.items 
    };
}



const getDetalhesFatura = async (data, token) => {    
    const retorno = await basicFetch(`/api/faturas/detalhes`, data, token)   
    return {
        slug: 'faturas',
        title: 'faturas',
        code: retorno.code,
        items: retorno.items 
    };
}


const gerarBoletosFatura = async (data, token) => {    
    const retorno = await basicFetch(`/api/boletos/postGerarBoletosFatura`, data, token)       
    return {
        slug: 'boletos',
        title: 'boletos',
        code: retorno.code,
        items: retorno.items 
    };
}
/* Fim convertidos para nova versão */



const getConfig = async (token) => {
    
    let retorno = await basicGet(`/api/configuracoes`, [], token);
    return {
        slug: 'configuracoes',
        title: 'Configurações',
        code: retorno.code,
        items: retorno.items
    };
}

const saveConfig = async (data, token) => {
    const retorno = await basicFetch(`/api/configuracoes`, data, token)
    return {        
        code: retorno.code,        
        data: retorno.items
    }    
}

//-- Obtém os itens de configuração antes de ter o token
//-- Ou seja, configurações antes de fazer o login
const getConfig2  = async () => {
    const res = await axios({
        method: 'GET',
        url: `${API_BASE}/api/configuracoes2`,         
        headers: {"Content-Type": "application/json;charset=UTF-8"}      
      })
      return (res.data);
};






const cancelBoleto = async(nosso_numero, token) => {
    const retorno = await basicDelete(`/api/boletos`, nosso_numero, token)                 
    return {
        slug: 'boleto',
        title: 'boleto',
        status: retorno.items.status,
        mensagem: retorno.items.mensagem 
    };
}




const getFatura = async (id, token) => {    
    const retorno = await basicFetch(`/api/faturas/getFatura`, id, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        code: retorno.code,
        items: retorno.items 
    };
}

const cancelFatura = async(data, token) => {
    const retorno = await basicFetch(`/api/boletos/cancelBoletoeFatura`, data, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        status: retorno.items.status,
        mensagem: retorno.items.mensagem 
    };
}


function printForm(formId, url, onSubmitForm, fatura, tipo, ilpi_id = 0, parcial = false) { 
    return(
      <form onSubmit={onSubmitForm}
        id={formId}
        action={`${API_BASE}/${url}`} 
        method="post" 
        target="_blank">
        <input type="hidden" name='fatura_id' id="fatura_id" value={fatura ? fatura.id : 0} />
        <input type="hidden" name='tipo' value={tipo} />
        <input type="hidden" name='cod_cliente' id="cod_cliente" value={fatura ? fatura.cod_cliente : 0} />
        <input type="hidden" name='ilpi_id' id="ilpi_id" value={ilpi_id} />
        <input type="hidden" name='parcial' id="parcial" value={parcial} />
      </form>
    )
}


const getUsuarios = async (data, token) => {    
    const retorno = await basicFetch(`/api/usuarios/listar`, data, token)                 
    return {
        slug: 'usuarios',
        title: 'usuarios',
        code: retorno.code,
        items: retorno.items 
    };
}





const changePassword = async (data, token) => {
    return await basicFetch(`/api/change_password`, data, token)    
}



const reset_password  = async (data) => {
    const resp = await basicFetch('/auth/reset_password', data);        
    return resp;
}


const getChartData = async (data, token) => {    
    const retorno = await basicFetch(`/api/faturas/getChartData`, data, token)                 
    return {
        code: retorno.code,
        items: retorno.items 
    };
}




const fetchData = {
    basicFetch,
    makeLogin, getConfig, getConfig2, saveConfig, getControlados, getFatura, salvarFatura, getIlpis, 
    getProdutos, cancelFatura, printFatura, printForm,  getFaturas, gerarBoleto, getBoletos, getBoletoPDF, cancelBoleto, 
    getUsuarios, changePassword, reset_password, getURL, getDetalhesFatura, getResidentesIlpi, enviarEmailFaturaBoleto,
    gerarBoletosFatura, getChartData
};

export default fetchData


/*
.then(function (res) {
        return (res.data);
    })
    .catch(function (error) {
        if (error.response) {
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
          
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 

          //-- outros erros: retorna o erro completo
          const erro = {
              status: error.response.status,   
              messsage: error.response.data,
              statusText: error.response.statusText           
          }          
          return (erro);
        }
    });  

.then(res => {
        return (res.data);
    })
    .catch(error => {
        if (error.response) {
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 
        }        
        //-- outros erros: retorna o erro completo
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.code           
        }          
        return (erro);
    });       
*/

