import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import fetchData from '../../global/FetchData';
import Loading from '../../global/Loading';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import "./Login.scss";
// import logo_prodabit from '../../assets/images/logo-prodabit-transp.png'
// import bgImage from '../../assets/images/bakground-procard-propdv3.png'

export default function EsqueceuSenha() {
  
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const MySwal = withReactContent(Swal);


  async function handleSubmit(event) {
    
    event.preventDefault(); 
    
    if(email.length < 8){
        MySwal.fire({
            title: 'Oops!',
            html: 'Email em formato inválido!',
            icon: 'warning'     
        })   
        return;     
    }

    setLoading(true);
    const data = await fetchData.reset_password({email});         
    
    if(data.code !== 200){
        MySwal.fire({
            title: 'Oops!',
            // html: 'Não foi possível resetar sua senha. Verifique o seu email tente novamente!',
            html: data.message,
            icon: 'warning'     
        })
        setLoading(false);        
    }
    else{        
        MySwal.fire({
            title: "Tudo certo!",
            html: "Senha alterada com sucesso. Verifique seu email.",
            icon: "success",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                window.location.href = '/';                
            }
        })    
    }
    setLoading(false);
  }  

  return (
    <div className="limiter">
        <div className="container-login">
            <div className="wrap-login">
                <div className="tilt-image">
                    {/* <img src='/assets/images/img-01.png' alt="IMG"/>  */}
                    {/* <img src={`/assets/images/${estilo.logo}`} alt=""/>                       */}
                </div>                

                <form className="login-form validate-form" onSubmit={handleSubmit}>
                    <span className="login-form-title" style={{"color": "#b30929"}}>
                        Resetar Senha
                    </span>
                    <Form.Group size="lg" controlId="login" className="wrap-input validate-input">
                        <Form.Control
                            className="input"
                            autoFocus
                            type="text"
                            name="email" 
                            autoComplete="off"
                            placeholder="Email" 
                            // value="1365"
                            onChange={(e) => setEmail(e.target.value)}                            
                        />
                        <span className="focus-input"></span>
                        <span className="symbol-input">
                            <i className="bi bi-envelope-fill" aria-hidden="true"></i>
                        </span>
                    </Form.Group>                    

                    <div className="container-login-form-btn">
                        <button className="login-form-btn btn-warning" type="submit">
                            Resetar Senha
                        </button>
                    </div>
                </form>
            </div>
            <div className="prodabit">                        
                <a href="http://sistemafrentedecaixa.com.br" target={"blank"} >
                    Powered by <b>Prodabit Sistemas</b>
                </a>
            </div>  
            {loading && <div className="overlay"><Loading /></div>}                  
        </div>        
    </div>
  );
}

