import React, {useState, useContext, useMemo, useCallback} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import TableHeader from '../../components/TableHeader/TableHeader';
import { PersonLinesFill, HouseCheck } from "react-bootstrap-icons";
import "./Faturas.scss";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <><input 
        type="text" 
        value={filterText} 
        onChange={onFilter}
        placeholder="Filtrar"
        aria-label="Search Input"
        className="search-input"           
        id="input-text-filter"
      />
      <button 
        type="button" 
        className="search-input-button"
        onClick={onClear}>X            
      </button></>
);

function FaturasParcial({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const {token, refresh, getActiveUser} = useContext(AppContext); 
    const [filterText, setFilterText] = useState('');
	  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [ilpi_id, setIlpiId] = useState(0);
    
    //-- Seta o título 
    React.useEffect(() => { 
      setTitle('Faturas');      
    },[setTitle])     
    
    
    const loadEmpresas = useCallback(async () => {
      setLoading(true);        
      const user = getActiveUser();      
      
      //-- Feito logout
      if(!user || user === null){        
        // Swal.fire('Sessão Expirada', 'Faça o login novamente', 'warning');
        window.location.href = '/'; 
        return;     
      }      
      
      const resp = await fetchData.getIlpis({usuario_id: user.id}, token);  
      setTableData(resp.items);   

      setLoading(false);
    },[setLoading, getActiveUser, token])


   
    //-- Carrega as ultimas faturas
    React.useEffect(() => {   
      loadEmpresas();   
    }, [loadEmpresas, refresh]);    
    
    
    const filteredItems = tableData.filter(
      item => item && (item.nome.toLowerCase().includes(filterText.toLowerCase()) || 
                      (item.status.toLowerCase().includes(filterText.toLowerCase()))
      )      
    );



    const onSubmitForm = (event) => {
      
      //event.preventDefault();
      // const fatura_id = event.target.fatura_id.value;
      // setFatura({...fatura, cod_cliente: 0});      
      // console.log(fatura_id)

    }


    const submitFatura = (id) => {      
      setIlpiId(id);      
    }

    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {  
        if (filterText) {  
          setResetPaginationToggle(!resetPaginationToggle);  
          setFilterText('');  
        }  
      };
      return (  
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);


    // p.NOMECOMPLETO, cli.NOMECLI, v.DATA, v.QUANTPROD,  conv.CODEMP as CODEMP, emp.nomeemp
    const conditionalRowStyles = [
      {
        when: row => row.status === 'CANCELADO',
        style: {
          textDecoration: 'line-through',
          color: '#d263a2'        
        },
      },
    ];
    
    const colunas = [
      {
        name: 'ID',
        selector: row => row.id,        
        sortable: true,
        width: '10%'        
      },        
      {
        name: 'Cliente/Casa',
        selector: row => row.nome,   
        sortable: true,        
        width: '60%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
      },    
      {
        name: 'T.Envio',
        selector: row => row.tipo_envio_fatura,   
        sortable: true,        
        width: '8%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
      },  
      {
        name: 'Status',
        selector: row => row.ativo,   
        sortable: true,
        width: '8%'
      },    
      {
        name: 'Faturas',        
        width: '8%',
        cell:(row) => (
          <>
          <button
            className="icon-table me-1"             
            title="Fatura Casa"
            type="submit" 
            form="printFaturaCasa"            
            onClick={() => submitFatura(row.id, 'casa')}
            >            
            <HouseCheck color="royalblue" size={15} ></HouseCheck>
          </button>   
          <button
            className="icon-table me-1"             
            title="Fatura Família"
            type="submit" 
            form="printFaturaFamilia"
            onClick={() => submitFatura(row.id, 'familia')}            
            >            
            <PersonLinesFill color="royalblue" size={15} ></PersonLinesFill>
          </button>
          </>
        )
      },      
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
    };


    return(     
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                        
          <DataTable
            title={TableHeader('Faturas Parciais', false, null, false, '')}
            columns={colunas}
            striped={true}
            conditionalRowStyles={conditionalRowStyles}
            // defaultSortFieldId={0}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}            
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            //selectableRows
            persistTableHead                        
          />
        </div>  
        {fetchData.printForm('printFaturaCasa', 'api/printFatura', onSubmitForm, null, 'casa', ilpi_id, true)}
        {fetchData.printForm('printFaturaFamilia', 'api/printFatura', onSubmitForm, null, 'familia', ilpi_id, true)}        
      </div>
  )
}

export default FaturasParcial;