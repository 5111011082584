import React, {useState, useContext, useMemo} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import HeaderFilters from './HeaderFilters';
import { SendCheck, SendDash, Hourglass, XLg, CheckCircleFill, ExclamationDiamondFill } from "react-bootstrap-icons";
import './Boletos.scss'
// import Swal from 'sweetalert2'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <><input 
        type="text" 
        value={filterText} 
        onChange={onFilter}
        placeholder="Filtrar"
        aria-label="Search Input"
        className="search-input"           
        id="input-text-filter"
      />
      <button 
        type="button" 
        className="search-input-button"
        onClick={onClear}>X            
      </button></>
);

function Boletos({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const [status, setStatus] = useState('');
    const [qtde, setQtde] = useState(500);
    const {token} = useContext(AppContext); 
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


    React.useEffect(() => {      
    
      setTitle('Boletos Ativos');      

      const loadData = async () => {
        setLoading(true);        
        
        
        const dados = {qtde, status}          
        const retorno = await fetchData.getBoletos(dados, token);  
        //console.log(retorno);
        
        //-- Sessão expirada
        if(retorno.code === 401){
          setLoading(false);
          sessionStorage.clear();    
          window.location.href = '/';      
        }
  
        setTableData(retorno.items)
        setLoading(false);
      }; 
      loadData();         
    }, [setLoading, setTitle, token, qtde, status]);      

    
    
    const filteredItems = tableData.filter(
      item => item && (item.empresa.toLowerCase().includes(filterText.toLowerCase()) || 
                       (item.cliente && item.cliente.toLowerCase().includes(filterText.toLowerCase())) || 
                       item.status_boleto.toLowerCase().includes(filterText.toLowerCase()) || 
                      (item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
      )      
    );
    
    
    const formatarStatusEnvio = (status_envio) => {
      switch (status_envio) {
        case '0': return <SendDash className='text-danger' title="não enviado"></SendDash>; 
        case '1': return <SendCheck className='text-success' title="enviado"></SendCheck>
        default: return <Hourglass className='text-secondary' title="aguardando envio" ></Hourglass>
      }
    }   


    const formatarStatusBoleto = (status) => {
      switch (status) {
        case 'PAGO':      return <CheckCircleFill className='text-success' title="boleto pago"></CheckCircleFill>; 
        case 'EXPIRADO':  return <ExclamationDiamondFill className='text-warnig' title="boleto expirado"></ExclamationDiamondFill>;
        case 'VENCIDO':   return <ExclamationDiamondFill className='text-danger' title="boleto vencido"></ExclamationDiamondFill>;
        case 'CANCELADO': return <XLg className='text-secondary' title="boleto cancelado"></XLg>;
        default: return <Hourglass className='text-info' title="enviado"></Hourglass>
      }
    }

    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {  
        if (filterText) {  
          setResetPaginationToggle(!resetPaginationToggle);  
          setFilterText('');  
        }  
      };
      return (  
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);


    const onFormChange = (evt) => {      
      let nome = evt.target.name;
      let value = evt.target.value;

      if(nome === 'status') setStatus(value);
      if(nome === 'qtde') setQtde(value);
    }


    const colunas = [
      {
        name: 'id',
        selector: row => row.id,             
        sortable: true,
        width: '8%'
      },   
      {
        name: 'Status',
        selector: row => formatarStatusBoleto(row.status_boleto),
        sortable: true,
        width: '7%'
      },      
      {
          name: 'Desc. Status',
          selector: row => row.status_boleto,
          sortable: true,
          width: '10%'
      },      
      {
        name: 'Valor',
        selector: row => row.valor,             
        sortable: true,
        width: '8%'
      },        
      {
        name: 'Vlr.Pago',
        selector: row => row.valor_pago,             
        sortable: true,
        width: '8%'
      },  
      {
        name: 'Vencimento',
        //selector: row => helper.MySQLToDate(row.updated_at),             
        selector: row => row.vencimento,             
        sortable: true,
        width: '9%'
      },        
      {
        name: 'Atualizado',
        //selector: row => helper.MySQLToDate(row.updated_at),             
        selector: row => moment(row.updated_at).format('YYYY-MM-DD'),
        sortable: true,        
        width: '9%'
      },    
      {
        name: 'Empresa',
        selector: row => row.empresa,
        sortable: true,
        width: '14%'
      },  
      {
          name: 'Cliente',
          selector: row => row.cliente,
          sortable: true,
          width: '18%'
      },
      {
        name: 'EMail',
        selector: row => formatarStatusEnvio(row.status_envio),
        sortable: true,
        width: '10%'
    },      
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
  };


    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
          <DataTable            
            title={HeaderFilters('Últimos Boletos', onFormChange)}
            // title="Últimos Boletos"
            columns={colunas}            
            data={filteredItems}
            striped={true}
            // defaultSortFieldId={5}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}    
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            // persistTableHead   
            // fixedHeader={true}
            // fixedHeaderScrollHeight={'600px'}
          />
        </div>  
      </div>
  )
}

export default Boletos;