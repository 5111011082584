import React, {useState, useContext} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import "./EditFatura.scss";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ExclamationSquare } from "react-bootstrap-icons";
import moment from 'moment';
// import Swal from 'sweetalert2'


function EditFatura({show, fatura, empresaLst, ilpi_id, setShowModal, handleChange, onSubmitHandler, setFatura}){

    const [residentes, setResidentes] = useState([])
    const {token} = useContext(AppContext); 

    //-- Carrega lista de residentes para utilizar no formulário de edição
    React.useEffect(() => {      
        const loadData = async () => {
            if(ilpi_id > 0){
                const dados = {"ilpi_id": ilpi_id} 
                const retorno = await fetchData.getResidentesIlpi(dados, token);  
                setResidentes(retorno.items);
                
                if(retorno.items[0])
                    generateDateInicioeFim(retorno.items[0].dia_fatura, retorno.items[0].venc_fatura);
            }
            else{
                setResidentes([]);
            }
        }; 
        loadData();         
    }, [token, ilpi_id]);   
    
    
    const handleClose = () => {
        setShowModal(false)
    } 


    const generateDateInicioeFim = (inicio, vencimento) => {
        
        const current =  moment()
        const anterior = moment().subtract(1, 'months')
        
        if(!inicio) inicio = 1;        
        if(!vencimento) vencimento = 1;
    
        const dtini = anterior.date(inicio).format('YYYY-MM-DD');
        const dtfim = current.date(inicio-1).format('YYYY-MM-DD'); 
        let venc  = current.date(vencimento).format('YYYY-MM-DD');
        if(current < current.date(vencimento)) 
            venc  = current.date(vencimento).add(1, 'months').format('YYYY-MM-DD');

        setFatura({...fatura, 'inicio': dtini, 'fim': dtfim, 'vencimento': venc});    
    }


    return (
    <>
        <Modal 
            show={show} 
            onHide={handleClose} 
            centered={true} 
            scrollable={false}
            size="lg"                        
            aria-labelledby="modal-sizes-title-lg"            
        >
        <Modal.Header closeButton>
            <Modal.Title>{(fatura.ilpi_id > 0) ? fatura.nomeemp : 'Novo Registro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="formEditFatura" onSubmit={onSubmitHandler}>
                <Form.Group className="row mb-3" controlId="formEditFatura">
                    <Form.Group className="col-6 mb-3" controlId="ilpi_id">
                        <Form.Label>ILPI:</Form.Label>
                        <Form.Select name="ilpi_id" value={fatura.ilpi_id} aria-label="" className="form-select form-select-sm" onChange={handleChange}>
                            <option value="-1">Selecione a Casa/ILPI</option>
                            {empresaLst && 
                                empresaLst.map((item) => {
                                    return <option key={item.id} value={item.id}>{item.nome_abreviado}</option>
                                })                            
                            }                           
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-6 mb-3" controlId="residente_id">
                        <Form.Label>Residente:</Form.Label>
                        <Form.Select name="cod_cliente" aria-label="" className="form-select form-select-sm" onChange={handleChange}>
                            <option value="-1">TODOS</option>
                            {residentes && 
                                residentes.map((item) => {
                                    return <option key={item.codcli} value={item.codcli}>{item.nomecli}</option>
                                })                            
                            }                           
                        </Form.Select>                        
                    </Form.Group>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formEditFatura">
                    <Form.Group className="col-3 mb-3" controlId="dtInicio">
                        <Form.Label>Data Início:</Form.Label>
                        <Form.Control 
                            name="inicio"
                            type="date"
                            className="form-control form-control-sm"                        
                            //placeholder={data.dt_inicio}
                            onChange={handleChange}
                            value={fatura.inicio}                            
                        />
                    </Form.Group>
                    <Form.Group className="col-3 mb-3" controlId="dtFim">
                        <Form.Label>Data Fim:</Form.Label>
                        <Form.Control 
                            name="fim"
                            type="date"
                            className="form-control form-control-sm"                        
                            //placeholder={fatura.fim}
                            onChange={handleChange}
                            value={fatura.fim}
                        />
                    </Form.Group>
                    <Form.Group className="col-6 mb-3" controlId="dtFim">
                        <Form.Label>Vencimento</Form.Label>
                        <Form.Control
                            name="vencimento"
                            type="date"
                            className="form-control form-control-sm"
                            //placeholder={fatura.vencimento}
                            onChange={handleChange}
                            value={fatura.vencimento}                                                
                        />
                    </Form.Group>    
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formEditFatura">
                    <Form.Group className="col-3 mb-3" controlId="dtFim">
                        <Form.Check
                            inline
                            label="Cancelado"
                            name="status"                            
                            checked={fatura.status === 'CANCELED' || fatura.status === 'CANCELADO'}    
                            onChange={handleChange}                     
                            // id={`disabled-default-${type}`}
                        />
                    </Form.Group>
                </Form.Group>

                <Form.Group className="row mb-3" controlId="formEditFatura">
                    <Form.Group className="col-3 mb-3" controlId="dtFim">
                        {/* <Form.Label>Valor SDM:</Form.Label> */}
                        <Form.Control
                            name="valor_sdm"
                            type="hidden"
                            className="form-control form-control-sm"                        
                            onChange={handleChange}
                            value={fatura.valor_sdm}                        
                        />
                    </Form.Group>
                </Form.Group>
            </Form>
            <span className="small"><ExclamationSquare></ExclamationSquare> Somente serão listadas as ILPIs permitidas ao usuário logado e que estejam com o cadastro completo no DroganetPro.<br/>
                                    Caso não esteja aparecendo, verifique no cadastro da ILPI se o CNPJ e o Cod.VSM estão corretos.</span>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Fechar
            </Button>
            <Button type="submit" form="formEditFatura" variant="success">
            Salvar Registro
            </Button>
        </Modal.Footer>
        </Modal>
    </>
    );
}


export default EditFatura;