import React, {useState, useCallback, useContext} from 'react';
import { AppContext } from '../../global/context' 
import "./Home.scss";
import LineChart from '../../components/Charts/lines';
import BarChart from '../../components/Charts/Bars';
import fetchData from '../../global/FetchData';


function Home({setLoading, setTitle}) {    

    const [chartData, setChartData] = useState([]); 
    const {token, getActiveUser} = useContext(AppContext);  
  
    React.useEffect(() => {   

      const loadDataForCharts = async () => {
        setLoading(true);        
        const user = getActiveUser();              
        if(!user || user === null){        
          window.location.href = '/'; 
          return;     
        }      
        
        const dados = {}  
        const retorno = await fetchData.getChartData(dados, token); 
        //console.log(retorno.items.vendas.data) 
        
        //-- Sessão expirada
        if(retorno.code === 401){        
          setLoading(false);
          sessionStorage.clear();        
          window.location.href = '/';  
        }
        setChartData(retorno.items)        
        setLoading(false);     
      }

      setTitle('Droganet Finance Pro');
      loadDataForCharts();      
    }, [setTitle]);   


    return(     
        <div className="content-wrapper">
          { /* Content Header (Page header) */  }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h4 className="mt-3">Dashboard</h4>
                </div>{ /* /.col */  }
              </div>{ /* /.row */  }
            </div>{ /* /.container-fluid */  }
          </div>
          { /* /.content-header */  }

          { /* Main content */  }
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  {chartData.boletos &&
                    < LineChart chartData={chartData.boletos} setLoading={setLoading}/>
                  }
                </div>
                  
                <div className="col-lg-6">
                  {chartData.vendas &&
                    <BarChart chartData={chartData.vendas} setLoading={setLoading} />
                  }
                </div>
              </div>
            </div>
          </div>
          { /* /.content */  }


        { /* Main Footer */  }
        {/* <footer className="main-footer">
          <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.2.0
          </div>
        </footer> */}
      </div>
    )
}

export default Home;