import React, {createContext, useState} from 'react'

const AppContext = createContext();

const AppProvider = ({children}) => {

    var dt = new Date(); 
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [dataIni, setDataIni] = useState(startOfMonth(dt));
    const [dataFim, setDataFim] = useState(dt);
    const [refresh, setRefresh] = useState(false)    
    //const [procards, setIlpis] = useState([]);    
    //const [procard, setIlpi] = useState();    

    React.useEffect(() => {
      //getProcards();
    }, []) 
    
    
    async function setActiveUser(user){      
      sessionStorage.setItem('activeUser', JSON.stringify(user)); 
      
      // const list = getIlpis();
      // const item = list.find(x => x.id === id);   
      return user;     
      //console.log('procard', procard)
    }   


    function getActiveUser(){
       const user = sessionStorage.getItem('activeUser');      
       
      //-- Realizado logout
      // if(!user || user === null){        
      //   window.location.href = '/'; 
      //   return;     
      // }   
       
      return JSON.parse(user);
    }


    function getToken(){      
      const tokenString = sessionStorage.getItem('token');      
      const token = JSON.parse(tokenString);
      return (token) ? token : undefined;  //-- tem que colocar desta forma para não dar erro na primeira renderização    
    }
    const [token, setToken] = useState(getToken());

    function saveToken(token) {
      sessionStorage.setItem('token', JSON.stringify(token));      
      setToken(token);
    };    

    const openSidebar  = () => setIsSidebarOpen(true)
    const closeSidebar = () => setIsSidebarOpen(false)

    
    const data = {
      dataIni, setDataIni, dataFim, setDataFim, 
      isSidebarOpen, openSidebar, closeSidebar,
      getActiveUser, setActiveUser,
      token, setToken: saveToken,
      refresh, setRefresh
    }


    function startOfMonth(date){
      // return new Date(date.getFullYear() -1, date.getMonth(), 1);
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }  


    return(
      <AppContext.Provider value={ data }>
         {children}
      </AppContext.Provider>
    )
}

export {AppContext, AppProvider}