import React from 'react';
import './TableHeader.scss'
import { Button } from "react-bootstrap";


function TableHeader(title, showButtonNew, onClickNew, showButtonPrint, formPrintName) { 
    
  return(
    <div className="table-header-component">
        <div className="table-title">{title}</div><br/>
        <div>
        {showButtonPrint && <Button 
                        type="submit" 
                        form={formPrintName}
                        value="Submit"
                        style={{
                          backgroundColor: "#5b8249",
                          color: "#fff",
                          fontSize: "12px",
                          marginRight: "5px"}}                        
            >Imprimir</Button>}
        
        {showButtonNew && <Button                 
                style={{backgroundColor: "#1e8829",
                        color: "#fff",
                        fontSize: "14px",
                        marginRight: "8px",
                        padding: "7px 30px"}}
                onClick={onClickNew}
            >Novo Registro</Button>}
        </div>
    </div>
  )
}

export default TableHeader;



