import React  from 'react';
import "./DetalheFatura.scss";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { UpcScan, FiletypePdf, EnvelopeAt, FileEarmarkX } from "react-bootstrap-icons";


function DetalheFatura({show, fatura, setFatura, detalhes, setShowDetalheModal, enviarEmailFaturaBoleto, openLinkNewTab, handleDeleteFaturaResidente}){

    
    const conditionalRowStyles = [
        {
          when: row => row.status === '0', //-- cancelado
          style: {
            textDecoration: 'line-through',
            color: '#d263a2'        
          },
        },
    ];
    
    const colunas = [
    {
        name: 'Cód.Cli.',
        selector: row => row.codcli,        
        sortable: true,
        width: '10%'        
    },   
    {
        name: 'Residente',
        selector: row => row.nomecli,        
        sortable: true,
        width: '28%'        
    }, 
    {
        name: 'Total Produtos',
        selector: row => ('R$ ' + (parseFloat(row.total_produtos)).toFixed(2)),   
        sortable: true,
        width: '10%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
    },            
    {
        name: 'Devoluções',
        selector: row => ('R$ ' + (parseFloat(row.total_devolucoes)).toFixed(2)),   
        sortable: true,
        width: '10%'
        // minwidth: '15%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
    },    
    {
        name: 'Total SDM',
        selector: row => ('R$ ' + (parseFloat(row.total_sdm)).toFixed(2)),   
        sortable: true,
        width: '10%'
        // width: '12%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
    },   
    {
        name: 'Total PBM',
        selector: row => ('R$ ' + (parseFloat(row.total_pbm)).toFixed(2)),   
        sortable: true,
        width: '10%'
        // width: '12%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
    },  
    {
        name: 'total',
        selector: row => ('R$ ' + (parseFloat(row.total)).toFixed(2)),   
        sortable: true,
        width: '10%'
        // width: '38%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
    },    
    {
        name: 'Ações',          
        width: '15%',
        cell:(row) => (
        <>  
            <button
                className="icon-table me-1"             
                title="Excluir Fatura Cliente/Residente"
                type="button"             
                onClick={() => handleDeleteFaturaResidente(fatura.id, row.codcli)}            
                >            
                <FileEarmarkX color="red" size={15} ></FileEarmarkX>
            </button>  
            <button
                className="icon-table me-1"             
                title="Fatura Família"
                type="submit" 
                form="printFaturaFamilia"
                onClick={() => setFatura({...fatura, "cod_cliente": row.codcli})}
                cod_cliente={row.codcli}
                >            
                <FiletypePdf color="royalblue" size={15} ></FiletypePdf>
            </button>   
            <button
                className="icon-table me-1"             
                title="Exibir Boleto"
                type="button"             
                onClick={() => openLinkNewTab(row.nosso_numero, fatura.id, row.codcli, row.cnpjcli)}            
                >            
                <UpcScan color={(row.nosso_numero && row.nosso_numero !== "") ? 'green' : 'gray'} size={15} ></UpcScan>
            </button>              

            <button
                className="icon-table me-1"             
                title="Reenviar Email Residente"
                type="submit"             
                onClick={() => enviarEmailFaturaBoleto(fatura.id, row.codcli, 'familia', row.emailcli, true)} 
                id={row.id} 
                >            
                <EnvelopeAt color="royalblue" size={15} ></EnvelopeAt>
            </button>


            {/* <EnvelopeAtFill 
                className="icon-table" 
                color="#ccc" 
                size={15} 
                onClick={() => enviarEmailFaturaBoleto(fatura.id, row.codcli, 'familia', row.emailcli, true)} 
                id={row.id} 
                title="Reenviar Email Residente">
            </EnvelopeAtFill>      */}
        </>
        ),
        // ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,                
    },    
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',      
    };


    return (
    <>
        <Modal 
            show={show} 
            onHide={() => setShowDetalheModal(false)} 
            centered={true} 
            scrollable={false}
            size="xl"                        
            aria-labelledby="example-modal-sizes-title-lg"
            enforceFocus={false}            
            >
        <Modal.Header closeButton>
            <Modal.Title>Detalhes da Fatura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DataTable
                // title={"Detalhes da Fatura [" + fatura_id+ "]"}
                columns={colunas}
                striped={true}
                conditionalRowStyles={conditionalRowStyles}
                // defaultSortFieldId={0}            
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[14,30,50,100]}             
                paginationPerPage={14}            
                data={detalhes}
                noDataComponent="Nenhum resultado encontrado" //or your component
                // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                //selectableRows
                persistTableHead                        
          />

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDetalheModal(false)}>
            Fechar
            </Button>            
        </Modal.Footer>
        </Modal>
    </>
    );
}


export default DetalheFatura;