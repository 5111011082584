const MySQLToDate = (dataString, retornaHora = false) => {
    
    const data = dataString.substring(0,10);
    const hora = dataString.substring(11,20);
    
    var [yyyy, mm, dd] = data.split('-')
    var [hh, ii]   = hora.split(':');

    let result = `${dd}/${mm}/${yyyy}`;
    if(retornaHora) result += ` ${hh}:${ii}`
    return  result
};

const DateToMySQL = (dataString, retornaHora = false) => {
    
    const data = dataString.substring(0,10);
    const hora = dataString.substring(11,20);
    
    var [yyyy, mm, dd] = data.split('/')
    var [hh, ii]   = hora.split(':');

    let result = `${yyyy}-${mm}-${dd}`;
    if(retornaHora) result += ` ${hh}:${ii}`
    return  result
};


const formatMoney = (value) => {      
    if (value) {
      value = Number(value);
      return `R$ ${value.toFixed(2)}`;
    } else {
      return "-";
    }
};


function adicionaZeros(num, size) {
  
  if(num){
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}




const helper = {
    MySQLToDate, DateToMySQL, formatMoney, adicionaZeros
    // getVendasPeriodo,
    // getProdutosVendidosPeriodo,
    // getRecargasPeriodo,
    // makeLogin,
    // getPaymentSession,
    // getResumoConsumo,
    // getConfig, getConfig2, saveConfig, saveProcardProdutosProibidos,
    // getProdutos, getControlados, getFaturas,
    // changePassword, reset_password
};

export default helper

