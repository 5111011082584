import React, {useState} from 'react';
import {  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SquareFill, ArrowUp } from "react-bootstrap-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,  
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Gráfico de Boletos Emitidos',
    },
  },
};


function LineChart({chartData, setLoading}) {    

    const [data, setData] = useState()

    React.useEffect(() => {      
      setLoading(true);
      const labels = chartData.labels;      
      const aux = {
        labels,
        datasets: [
            {
                label: 'Em Aberto',
                data: chartData.emitidos,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',      
            },
            {
                label: 'Pagos',
                data: chartData.pagos,
                borderColor: 'rgb(32, 201, 151)',
                backgroundColor: 'rgba(32, 201, 151, 0.5)',      
            },
            {
                label: 'Vencidos',
                data: chartData.vencidos,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
        ],
      };
      setData(aux);      
      setLoading(false);
    }, []);

    return(           
        <div className="card">
        <div className="card-header border-0">
            <div className="d-flex justify-content-between">
            <h3 className="card-title mt-3">Emissão de Boletos</h3>                        
            </div>
        </div>
        <div className="card-body">
            <div className="d-flex">
            <p className="d-flex flex-column">
                {chartData && 
                    <span className="text-bold" style={{'fontSize': '0.9rem'}}>{chartData.qtde}</span>
                }                                
                <span className="text-muted">Boletos Emitidos no Mês Atual<br/>&nbsp;</span>
            </p> 
            </div>
            { /* /.d-flex */  }

            <div className="position-relative mb-4">
            {data && 
                <Line options={options} data={data} />
            } 
            </div>

            {/* <div className="d-flex flex-row justify-content-end">
              <span className="mr-2">
                  <SquareFill className="text-primary"></SquareFill> This Week
              </span>

              <span>
                <SquareFill className="text-gray"></SquareFill> Last Week
              </span>
            </div> */}
        </div>
        </div>
  )
}

export default LineChart;