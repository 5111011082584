import React, {useContext, useState} from 'react';
import './TopBar.scss';
import { AppContext } from '../../global/context' 
import {CreditCard2Front} from 'react-bootstrap-icons';
import {Person} from 'react-bootstrap-icons';
import {List} from 'react-bootstrap-icons';
import helper from '../../global/Helper'; 

function TopBar({title}) {

  //-- recebo os parametros do context    
  const {getActiveUser, isSidebarOpen, openSidebar, closeSidebar} = useContext(AppContext);   
  const [user, setUser] = useState({})  
  
  React.useEffect(() => {      
    function loadAll(){            
      const active = getActiveUser()      
      setUser(active);
    }
    loadAll();    
  }, [getActiveUser])   



  // function parseDate(input, format) {
  //   format = format || 'yyyy-mm-dd'; // default format
  //   var parts = input.match(/(\d+)/g), 
  //       i = 0, fmt = {};
  //   // extract date-part indexes from the format
  //   format.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });
  
  //   return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
  // }


  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   //const procard = procards.find(x => x.numero === value);
  //   //console.log(procard);
  //   setActiveUser(value);      
  //   setRefresh(!refresh);
  // }

  const handleOpenCloseSideBar = () => {
    isSidebarOpen ? closeSidebar() : openSidebar();
  }



  return (
    <>
      {user && <>
      <nav className="navbar-prodabit">                      
        <List className="menu-hamburguer" onClick={handleOpenCloseSideBar} />
        <h5>{title}</h5>
      </nav>

      <div className="info-user">
        <div className='select--procard'>
          <CreditCard2Front className="mb-1 me-1" color="#3a0733" size={15} />
          <b> CÓDIGO:</b> <span className="ms-2">{helper.adicionaZeros(user.id, 5)}</span>          
        </div>  
        <div>
          <Person className="mb-1 me-1" color="#3a0733" size={15} />        
          {/* <b className="me-2"> USUÁRIO:</b> {procard.usuario} */}
          <b className="me-2"> USUÁRIO:</b> {user.nome}
        </div>
        {/* <div>  
          <Wallet className="mb-1 me-1" color="#3a0733" size={15} />  
          <b> SALDO ATUAL: {procard.saldo} </b>
        </div>   */}
        {/* <div>
          <span className="blockquote-footer">Sincronizado em: ...</span>
        </div> */}
      </div>
    </>}
  </>  
  )
}

export default TopBar;
