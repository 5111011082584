import React, {useState, useContext, useMemo} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import { PersonHearts, Buildings, XCircle, WindowDock } from "react-bootstrap-icons";
import "./Usuarios.scss";
// import Swal from 'sweetalert2'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <><input 
        type="text" 
        value={filterText} 
        onChange={onFilter}
        placeholder="Filtrar"
        aria-label="Search Input"
        className="search-input"           
        id="input-text-filter"
      />
      <button 
        type="button" 
        className="search-input-button"
        onClick={onClear}>X            
      </button></>
);

function Usuarios({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const [empresa] = useState(0);
    const {token} = useContext(AppContext); 
    const [filterText, setFilterText] = useState('');
	  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


    React.useEffect(() => {      
    
      setTitle('Usuários Cadastrados');      

      const loadData = async () => {
        setLoading(true);        
        
        
        const dados = {
          empresa_id: empresa,
        }  
        
        const retorno = await fetchData.getUsuarios(dados, token);  
        
        //-- Sessão expirada
        if(retorno.code === 401){
          setLoading(false);
          sessionStorage.clear();    
          window.location.href = '/';      
        }
  
        setTableData(retorno.items)
        setLoading(false);
      }; 
      loadData();         
    }, [setLoading, setTitle, token, empresa]);      

    
    const filteredItems = tableData.filter(
      item => item.username && item.username.toLowerCase().includes(filterText.toLowerCase())      
    );


    const handleButtonClick = (state) => {
      alert('Detalhes do Usuário ' + state.target.id);
    };

    
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {  
        if (filterText) {  
          setResetPaginationToggle(!resetPaginationToggle);  
          setFilterText('');  
        }  
      };
      return (  
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);


    // p.NOMECOMPLETO, cli.NOMECLI, v.DATA, v.QUANTPROD,  conv.CODEMP as CODEMP, emp.nomeemp
    const colunas = [
      {
        name: 'ID',
        selector: row => row.id,        
        sortable: true,
        width: '10%'        
      },        
      {
        name: 'EMail/Login',
        selector: row => row.username,   
        sortable: true,
        width: '75%'
      },    
      {
        name: 'Ações',        
        cell:(row) => (
          <>
          <WindowDock className="icon-table" color="orange" size={15} onClick={handleButtonClick} id={row.id} title="Boletos"></WindowDock>        
          <Buildings className="icon-table" color="royalblue" size={15} onClick={handleButtonClick} id={row.id} title="Fatura Casa"></Buildings>        
          <PersonHearts className="icon-table" color="royalblue" size={15} onClick={handleButtonClick} id={row.id} title="Fatura Família"></PersonHearts>        
          <XCircle className="icon-table" color="red" size={15} onClick={handleButtonClick} id={row.id} title="Cancelar Fatura"></XCircle>        
          </>
        ),
        // ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,                
        width: '15%'
      },    
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
  };


    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
          <DataTable
            title="Lista de Usuários"
            columns={colunas}
            striped={true}
            // defaultSortFieldId={0}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}            
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead
          />
        </div>  
      </div>
  )
}

export default Usuarios;